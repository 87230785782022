<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">Edit Template</div>
          <template-form
            v-if="!isLoading"
            :template="template"
            @submit="onSave"
            @cancel="onCancel"
          />
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TemplateForm from '@/components/templates/TemplateForm.vue'
import BaseCard from '@/components/common/BaseCard.vue'

export default {
  components: {
    TemplateForm,
    BaseCard,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  mounted() {
    this.getTemplate(this.$route.params.templateId)
  },
  computed: {
    ...mapState({
      template: (state) => state.template.template,
    }),
  },
  methods: {
    ...mapActions(['fetchTemplateDetail', 'updateTemplate']),
    async getTemplate(templateId) {
      try {
        this.isLoading = true
        await this.fetchTemplateDetail(templateId)
      } finally {
        this.isLoading = false
      }
    },
    backToTemplateView() {
      this.$router.push({
        name: 'templateDetail',
        params: {
          templateId: this.$route.params.templateId,
        },
      })
    },
    async onSave(saveTemplate) {
      try {
        await this.updateTemplate({
          templateId: this.$route.params.templateId,
          template: saveTemplate,
        })
        this.backToTemplateView()
      } catch (err) {
        console.error(err)
      }
    },
    onCancel() {
      this.backToTemplateView()
    },
  },
}
</script>

<style lang="scss">
.v-card-heading {
  position: relative;
  top: -48px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
